import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public categories = [
    {
      name: 'Principal',
      pages: [
        { title: 'Checklists', url: '/checklists' },
        { title: 'Rotinas', url: '/rotinas'}
      ]
    },
    {
      name: 'Relatórios',
      pages: [
        { title: 'Envios de Checklist', url: '/envios-checklist' },
      ]
    },
    {
      name: 'Contexto',
      pages: [
        { title: 'Distribuidores', url: '/distribuidores' },
        { title: 'Lojas', url: '/lojas' },
      ]
    },
    {
      name: 'Configuração',
      pages: [
        { title: 'Listas', url: '/listas' },
        { title: 'Produtos', url: '/produtos' },
        { title: 'Sortimentos', url: '/sortimentos' },
      ]
    },
  ]
  constructor(public auth: AuthService) {}
}
