import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'checklists',
    pathMatch: 'full'
  },
  {
    path: 'distribuidores',
    loadChildren: () => import('./pages/distribuidores/distribuidores.module').then( m => m.DistribuidoresPageModule)
  },
  {
    path: 'lojas',
    loadChildren: () => import('./pages/lojas/lojas.module').then( m => m.LojasPageModule)
  },
  {
    path: 'checklists',
    loadChildren: () => import('./pages/checklists/checklists.module').then( m => m.ChecklistsPageModule)
  },
  {
    path: 'produtos',
    loadChildren: () => import('./pages/produtos/produtos.module').then( m => m.ProdutosPageModule)
  },
  {
    path: 'sortimentos',
    loadChildren: () => import('./pages/sortimentos/sortimentos.module').then( m => m.SortimentosPageModule)
  },
  {
    path: 'editar-checklist/:id',
    loadChildren: () => import('./pages/editar-checklist/editar-checklist.module').then( m => m.EditarChecklistPageModule)
  },
  {
    path: 'envios-checklist',
    loadChildren: () => import('./pages/checklist-entries/checklist-entries.module').then( m => m.ChecklistEntriesPageModule)
  },
  {
    path: 'listas',
    loadChildren: () => import('./pages/listas-produtos/listas-produtos.module').then( m => m.ListasProdutosPageModule)
  },
  {
    path: 'rotinas',
    loadChildren: () => import('./pages/routines/routines.module').then( m => m.RoutinesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
