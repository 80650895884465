// tslint:disable variable-name
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get loggedIn() { return this.__loggedIn }
  get companyId() { return this.__companyId}
  __loggedIn = false
  __companyId
  constructor(private afAuth: AngularFireAuth, private firestore: AngularFirestore, private navCtrl: NavController) {
    this.afAuth.authState.subscribe(authState => {
      if(authState) {
        this.firestore.doc(`users/${authState.uid}`).get().subscribe(user => {
          const userData: any = user.data()
          this.__companyId = userData.company.id
          this.__loggedIn = true
          this.navCtrl.navigateRoot('checklists')
        })
      } else {
        this.__loggedIn = false
        this.__companyId = undefined
        this.navCtrl.navigateRoot('login')
      }
    })
  }

  loginEmail(email, password) {
    try {
      this.afAuth.signInWithEmailAndPassword(email, password)
    } catch(e) {
      console.log(e)
    }
  }
  logout() {
    this.afAuth.signOut()
  }
}
